<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="text-center">
              <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('externalUserIrrigation.scheme_application') }}</h5>
            </div>
          </template>
          <template v-slot:body>
            <profile-status-msg :preConditionLoader="preConditionLoader" :profileStatus="profileStatus" :profile-url="'/irrigation/my-profile'" :inForm="true"/>
            <!-- <div class="font-weight-bold mb-2">
              {{ $i18n.locale === 'en' ? 'Please, complete your' : 'এই ফর্ম পূরণ করার আগে, দয়া করে আপনার'}}
              <router-link to="/irrigation/my-profile">
              {{ $i18n.locale === 'en' ? 'profile' : 'প্রোফাইল' }}
              </router-link>
              {{ $i18n.locale === 'en' ? 'before fillout this form.': 'সম্পূর্ণ করুন' }}
            </div> -->
            <b-overlay :show="load">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <div @click="displayProfileCheckAlert">
                    <b-row>
                        <b-col>
                            <h5 class="ex_form_title">{{ $t('externalUserIrrigation.farmer_info') }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Organization" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                    v-model="schemeApplication.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Scheme Type" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="scheme_type_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                      {{ $t('irrigation_config.scheme_typel')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="schemeApplication.scheme_type_id"
                                    :options="schemeTypeList"
                                    id="scheme_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Power Source" rules="">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="sub_scheme_type_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irrigation_config.sub_scheme_type_list')}}
                                  </template>
                                <b-form-select
                                    v-model="schemeApplication.sub_scheme_type_id"
                                    :options="subSchemeTypeList"
                                    id="sub_scheme_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Pump Capacity" vid="pump_capacity_id">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="pump_capacity_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('irrigation_config.pump_capacity')}}
                                  </template>
                                <b-form-select
                                    v-model="schemeApplication.pump_capacity_id"
                                    :options="pumpCapacityList"
                                    id="pump_capacity_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Name" vid='name' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="name"
                                slot-scope="{ valid, errors }"
                            >
                                  <template v-slot:label>
                                    {{ $t('user.name')}} <span class="text-danger">*</span>
                                  </template>
                                <b-form-input
                                id="name"
                                v-model="schemeApplication.name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Name (Bn)" vid='name_bn' rules="required">
                              <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('user.name_bn')}} <span class="text-danger">*</span>
                                  </template>
                                    <b-form-input
                                    id="name_bn"
                                    v-model="schemeApplication.name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Father name" vid='father_name' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="father_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.father_namef')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                id="father_name"
                                v-model="schemeApplication.father_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Father name (Bn)" vid='father_name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    :label="$t('externalUserIrrigation.father_name_bn')"
                                    label-for="father_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalUserIrrigation.father_name_bn')}} <span class="text-danger">*</span>
                                  </template>
                                    <b-form-input
                                    id="father_name_bn"
                                    v-model="schemeApplication.father_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Mother name" vid='mother_name' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="mother_name"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.mother_namef')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                id="mother_name"
                                v-model="schemeApplication.mother_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Mother name (Bn)" vid='mother_name_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="mother_name_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('externalUserIrrigation.mother_namef')}} <span class="text-danger">*</span>
                                  </template>
                                    <b-form-input
                                    id="mother_name_bn"
                                    v-model="schemeApplication.mother_name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="NID" vid='nid' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="nid"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.nid')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                type="number"
                                id="nid"
                                v-model="schemeApplication.nid"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Mobile" vid='far_mobile_no' rules="required|min:11|max:15">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="far_mobile_no"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('user.phone_no')}} <span class="text-danger">*</span>
                                  </template>
                                    <b-form-input
                                    type="number"
                                    id="far_mobile_no"
                                    v-model="schemeApplication.far_mobile_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="12">
                            <ValidationProvider name="sameAsFarmerProfile">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="2"
                                    label-for="sameAsFarmerProfile"
                                    slot-scope="{ errors }"
                                >
                                    <b-form-checkbox
                                        class="mt-1"
                                        id="sameAsFarmerProfile"
                                        v-model="sameAsFarmerProfile"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                    </b-form-checkbox>
                                    <template v-slot:label>
                                        {{ $t('externalUserIrrigation.same_as_farmer_info')}}
                                    </template>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- <b-col lg="4" sm="12">
                        </b-col> -->
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Division" vid="far_division_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="far_division_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                plain
                                :disabled="sameAsFarmerProfile"
                                v-model="addressHolder.far_division_id"
                                :options="divisionList"
                                id="far_division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="District" vid="far_district_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="far_district_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                      </template>
                                    <b-form-select
                                plain
                                    :disabled="sameAsFarmerProfile"
                                    v-model="addressHolder.far_district_id"
                                    :options="districtList"
                                    id="far_district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Upazila" vid="far_upazilla_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="far_upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                plain
                                :disabled="sameAsFarmerProfile"
                                v-model="addressHolder.far_upazilla_id"
                                :options="upazilaList"
                                id="far_upazila_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Union" vid="far_union_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="far_union_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                      </template>
                                    <b-form-select
                                plain
                                    :disabled="sameAsFarmerProfile"
                                    v-model="addressHolder.far_union_id"
                                    :options="unionList"
                                    id="far_union_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Village" vid='far_village' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="far_village"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.villagef')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                id="far_village"
                                v-model="schemeApplication.far_village"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Village (Bn)" vid='far_village_bn' rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="far_village_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                      {{ $t('externalUserIrrigation.villagef')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                    id="far_village_bn"
                                    v-model="schemeApplication.far_village_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Office" vid='office_id' rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="office_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('warehouseFarmer.office')}} <span class="text-danger">*</span>
                                </template>
                                  <b-form-select
                                    id="office_id"
                                    v-model="schemeApplication.office_id"
                                    :options="officeList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="my-2">
                            <h5 class="ex_form_title">{{ $t('externalUserIrrigation.scheme_manager_info') }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12">
                          <ValidationProvider name="sameAsFarmerProfileSch">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="2"
                                  label-for="sameAsFarmerProfileSch"
                                  slot-scope="{ errors }"
                              >
                                  <template v-slot:label>
                                      {{ $t('externalUserIrrigation.same_as_farmer_info')}}
                                  </template>
                                  <b-form-checkbox
                                      class="mt-1"
                                      id="sameAsFarmerProfileSch"
                                      @change="setschemeAddress"
                                  >
                                  </b-form-checkbox>

                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- <b-col lg="4" sm="12">
                        </b-col> -->
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Name" vid='sch_man_name' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_name"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                {{ $t('user.name')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="sch_man_name"
                              v-model="schemeApplication.sch_man_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                            <ValidationProvider name="Name (Bn)" vid='sch_man_name_bn' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="sch_man_name_bn"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('user.name_bn')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                id="sch_man_name_bn"
                                v-model="schemeApplication.sch_man_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Father name" vid='sch_man_father_name' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              :label="$t('externalUserIrrigation.father_namef')"
                              label-for="sch_man_father_name"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.father_namef')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="sch_man_father_name"
                              v-model="schemeApplication.sch_man_father_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Father name (Bn)" vid='sch_man_father_name_bn' rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="sch_man_father_name_bn"
                            slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.father_name_bn')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                              id="sch_man_father_name_bn"
                              v-model="schemeApplication.sch_man_father_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Mother name" vid='sch_man_mother_name' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_mother_name"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.mother_namef')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                              id="sch_man_mother_name"
                              v-model="schemeApplication.sch_man_mother_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Mother name (Bn)" vid='sch_man_mother_name_bn' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_mother_name_bn"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.mother_name_bn')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                              id="mother_name_bn"
                              v-model="schemeApplication.sch_man_mother_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="NID" vid='sch_man_nid' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_nid"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('externalUserIrrigation.nid')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                              type="number"
                              id="sch_man_nid"
                              v-model="schemeApplication.sch_man_nid"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Mobile" vid='sch_man_mobile_no' rules="required|min:11|max:15">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_mobile_no"
                              slot-scope="{ valid, errors }"
                          >
                            <template v-slot:label>
                              {{ $t('user.phone_no')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                              type="number"
                              id="sch_man_mobile_no"
                              v-model="schemeApplication.sch_man_mobile_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Division" vid="sch_man_division_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_division_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain

                              v-model="schemeApplication.sch_man_division_id"
                              :options="divisionList"
                              id="sch_man_division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="District" vid="sch_man_district_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_district_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain

                              v-model="schemeApplication.sch_man_district_id"
                              :options="schManDistrictList"
                              id="sch_man_district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Upazila" vid="sch_man_upazilla_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_upazila_id"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain

                              v-model="schemeApplication.sch_man_upazilla_id"
                              :options="schManUpazilaList"
                              id="sch_man_upazila_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Union" vid="sch_man_union_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_union_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain

                              v-model="schemeApplication.sch_man_union_id"
                              :options="schManUnionList"
                              id="sch_man_union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Village (En)" vid='sch_man_village' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  :label="$t('externalUserIrrigation.villagef')"
                                  label-for="sch_man_village"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('externalUserIrrigation.villagef')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-input
                              id="sch_man_village"
                              v-model="schemeApplication.sch_man_village"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Village (Bn)" vid='sch_man_village_bn' rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_village_bn"
                              slot-scope="{ valid, errors }"
                          >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.village_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="sch_man_village_bn"
                              v-model="schemeApplication.sch_man_village_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Scheme Manager Photo" vid='sch_man_photo' rules="ext:png,jpeg,jpg">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_photo"
                              slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                  {{ $t('externalUserIrrigation.upload_sch_man_photo') }} (Only .jpg .png .jpeg allowed)
                                </template>
                              <b-form-file
                                @change="onSchManFileChange"
                                v-model="schemeApplication.sch_man_photo"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider v-if="farmer_id !== 0">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="sch_man_village_bn"
                          >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.sch_man_photo') }}
                              </template>
                            <img :src="baseUrl + 'download-attachment?file=uploads/scheme-application/scheme-manager-photo/original/' + schemeApplication.sch_man_photo" class="height-150 width-150 img-fluid"/>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="my-2">
                          <h5 class="ex_form_title">{{ $t('externalUserIrrigation.proposed_pump_address') }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12">
                            <ValidationProvider name="sameAsFarmerProfilePumpAddress">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="2"
                                    label-for="sameAsFarmerProfilePumpAddress"
                                    slot-scope="{ errors }"
                                >
                                    <template v-slot:label>
                                        {{ $t('externalUserIrrigation.same_as_farmer_info')}}
                                    </template>
                                    <b-form-checkbox
                                        class="mt-1"
                                        id="sameAsFarmerProfilePumpAddress"
                                        v-model="sameAsFarmerProfilePumpAddress"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                    </b-form-checkbox>

                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <!-- <b-col lg="4" sm="12">
                        </b-col> -->
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Division" vid="pump_division_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="pump_division_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              :disabled="sameAsFarmerProfilePumpAddress"
                              v-model="addressHolderPump.pump_division_id"
                              :options="divisionList"
                              id="pump_division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="District" vid="pump_district_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              :label="$t('org_pro_district.district')"
                              label-for="pump_district_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              :disabled="sameAsFarmerProfilePumpAddress"
                              v-model="addressHolderPump.pump_district_id"
                              :options="pumpDistrictList"
                              id="pump_district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Upazila" vid="pump_upazilla_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="pump_upazila_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              :disabled="sameAsFarmerProfilePumpAddress"
                              v-model="addressHolderPump.pump_upazilla_id"
                              :options="pumpUpazilaList"
                              id="pump_upazila_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Union" vid="pump_union_id" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="pump_union_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              :disabled="sameAsFarmerProfilePumpAddress"
                              v-model="addressHolderPump.pump_union_id"
                              :options="pumpUnionList"
                              id="pump_union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Pump Latitude" vid='pump_latitude'>
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="pump_latitude"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.latitude')}}
                                </template>
                                  <b-form-input
                                  id="pump_latitude"
                                  v-model="schemeApplication.pump_latitude"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Pump Longitude" vid='pump_longitude'>
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="pump_longitude"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('globalTrans.longitude')}}
                                </template>
                                  <b-form-input
                                  id="pump_longitude"
                                  v-model="schemeApplication.pump_longitude"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Pump Mauza Name" vid='pump_mauza_no' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="pump_mauza_no"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.pump_mauza_nof')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="pump_mauza_no"
                              v-model="schemeApplication.pump_mauza_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Pump Mauza Name (Bn)" vid='pump_mauza_no_bn' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  :label="$t('externalUserIrrigation.pump_mauza_no_bn')"
                                  label-for="pump_mauza_no_bn"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('externalUserIrrigation.pump_mauza_no_bn')}} <span class="text-danger">*</span>
                                </template>
                                  <b-form-input
                                  id="pump_mauza_no_bn"
                                  v-model="schemeApplication.pump_mauza_no_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Pump jl no" vid='pump_jl_no' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="pump_jl_no"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.pump_jl_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="pump_jl_no"
                              v-model="schemeApplication.pump_jl_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Pump jl no (Bn)" vid='pump_jl_no_bn' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  :label="$t('externalUserIrrigation.pump_jl_no_bn')"
                                  label-for="pump_jl_no_bn"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.pump_jl_no_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="pump_jl_no_bn"
                              v-model="schemeApplication.pump_jl_no_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Pump plot no" vid='pump_plot_no' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="pump_plot_no"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.pump_plot_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="pump_plot_no"
                              v-model="schemeApplication.pump_plot_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Pump plot no (Bn)" vid='pump_plot_no_bn' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  :label="$t('externalUserIrrigation.pump_plot_no_bn')"
                                  label-for="pump_plot_no_bn"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.pump_plot_no_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="pump_plot_no_bn"
                              v-model="schemeApplication.pump_plot_no_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Total Farmer" vid='total_farmer' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="total_farmer"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.total_farmer')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="total_farmer"
                                v-model="schemeApplication.total_farmer"
                                :state="errors[0] ? false : (valid ? true : null)"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Command Area (Hector)" vid='command_area_hector' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="command_area_hector"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{ $t('externalUserIrrigation.command_area_hector')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                id="command_area_hector"
                                v-model="schemeApplication.command_area_hector"
                                :state="errors[0] ? false : (valid ? true : null)"
                                onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col sm="12" class="my-2">
                            <h5 class="ex_form_title">{{ $t('externalUserIrrigation.attachment') }}</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="4">
                        <ValidationProvider name="General minutes" vid='general_minutes' rules='size:2048|ext:png,jpeg,jpg,pdf'>
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            :label="$t('externalUserIrrigation.general_minutes')"
                            label-for="general_minutes"
                            slot-scope="{ valid, errors }"
                          >
                            <b-form-file
                              @change="onFileChangeGeneralMinutes"
                              v-model="schemeApplication.general_minutes"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="4">
                        <ValidationProvider name="Scheme Lands" vid='scheme_lands' rules='size:2048|ext:png,jpeg,jpg,pdf'>
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              :label="$t('externalUserIrrigation.scheme_lands')"
                              label-for="scheme_lands"
                              slot-scope="{ valid, errors }"
                          >
                              <b-form-file
                              @change="onFileChangeSchemeLands"
                              v-model="schemeApplication.scheme_lands"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="4">
                        <ValidationProvider name="Scheme map" vid='scheme_map' rules='size:2048|ext:png,jpeg,jpg,pdf'>
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              :label="$t('externalUserIrrigation.scheme_map')"
                              label-for="scheme_map"
                              slot-scope="{ valid, errors }"
                          >
                              <b-form-file
                              @change="onFileChangeSchemeMap"
                              v-model="schemeApplication.scheme_map"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col>
                        <div v-if="affidavitData" style="border: 1px solid gray;box-shadow: 1px 2px 6px grey;color: gray">
                          <div style="padding:10px">
                            <h5 class="text-center" style='font-weight:bold'>{{ $t('irrigation_config.affidavitl') }}</h5>
                            <p v-html="affidavitData"></p>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" class="my-2">
                        <h5 class="ex_form_title">{{ $t('externalUserIrrigation.document') + ' ' + $t('externalUserIrrigation.information') }}</h5>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="3">
                        <p class="text-dark">{{ $t('externalUserIrrigation.document_title_en') }}</p>
                      </b-col>
                      <b-col sm="3">
                          <p class="text-dark">{{ $t('externalUserIrrigation.document_title_bn') }}</p>
                      </b-col>
                      <b-col sm="3">
                          <p class="text-dark">{{ $t('externalUserIrrigation.attachment') }} (Max size: 2048 KB)</p>
                      </b-col>
                      <b-col sm="3">
                          <p class="text-dark">{{ $t('globalTrans.action') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg='3' sm='6'>
                        <ValidationProvider name="Document Title" vid='document_title' rules="">
                          <b-form-group
                              label-for="document_title"
                              slot-scope="{ valid, errors }"
                          >
                            <b-form-input
                                id="document_title"
                                v-model="document.document_title"
                                :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg='3' sm='6'>
                        <ValidationProvider name="Document Title (Bn)" vid='document_title_bn'>
                          <b-form-group
                              label-for="document_title_bn"
                              slot-scope="{ valid, errors }"
                          >
                              <b-form-input
                                  id="document_title_bn"
                                  v-model="document.document_title_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg='3' sm='6'>
                        <ValidationProvider name="Attachment" vid="attachment" rules="size:2048|ext:png,jpeg,jpg,pdf">
                          <b-form-group
                            label-for="attachment"
                            slot-scope="{ valid, errors }"
                          >
                            <b-form-file
                              v-on:change="onMultipleFileChange"
                              v-model="document.attachment"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg='3' sm='6'>
                        <b-form-group>
                          <b-button class="btn btn-outline-success mr-2" variant=" iq-bg-success" size="sm" @click="saveDocument">{{ $t('globalTrans.add') }}</b-button>
                          <b-button v-if="totalDocument > 0" class="btn-sm">Total Added : {{ totalDocument }}</b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-for="(tmpDoc,index) in documents" :key="index">
                      <b-col lg="3">
                        <p class="text-dark">{{ tmpDoc.document_title }}</p>
                      </b-col>
                      <b-col lg="3">
                        <p class="text-dark">{{ tmpDoc.document_title_bn }}</p>
                      </b-col>
                      <b-col lg="3">
                        <a :href="baseUrl + 'download-attachment?file=uploads/scheme-application-document/original/' + tmpDoc.attachment" title="Download"><i class="ri-download-cloud-fill"></i></a>
                      </b-col>
                      <b-col lg="3">
                        <b-button class="btn btn-outline-danger" variant=" iq-bg-danger" size="sm" @click="deleteAttachment(tmpDoc.id, index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                      </b-col>
                    </b-row>
                    <div class="text-right mt-5">
                      <button class="btn btn-primary" type="submit">{{ saveBtnName }}</button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import {
  farmerInfo,
  farmerSchemeApplicationStore,
  farmerSchemeApplicationUpdate,
  updateStatus,
  documentStore,
  getDocument,
  destroyDocument
} from '../../api/routes'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ProfileStatusMsg
  },
  data () {
    return {
      load: true,
      profileStatus: 0,
      preConditionLoader: false,
      schemeTypeList: [],
      subSchemeTypeList: [],
      pumpCapacityList: [],
      officeList: [],
      baseUrl: irriSchemeServiceBaseUrl,
      addressHolder: {
        far_division_id: '0',
        far_district_id: '0',
        far_upazilla_id: '0',
        far_union_id: '0'
      },
      addressHolderPump: {
        pump_division_id: '0',
        pump_district_id: '0',
        pump_upazilla_id: '0',
        pump_union_id: '0'
      },
      sameAsFarmerProfile: false,
      sameAsFarmerProfilePumpAddress: false,
      schemeApplication: {
        final_pay: 0,
        org_id: 0,
        scheme_type_id: '0',
        sub_scheme_type_id: 0,
        pump_capacity_id: 0,
        name: '',
        name_bn: '',
        father_name: '',
        father_name_bn: '',
        mother_name: '',
        mother_name_bn: '',
        nid: '',
        far_division_id: '0',
        far_district_id: '0',
        far_upazilla_id: '0',
        far_union_id: '0',
        office_id: '0',
        far_village: '',
        far_village_bn: '',
        far_mobile_no: '',
        sch_man_name: '',
        sch_man_name_bn: '',
        sch_man_father_name: '',
        sch_man_father_name_bn: '',
        sch_man_mother_name: '',
        sch_man_mother_name_bn: '',
        sch_man_nid: '',
        sch_man_division_id: '0',
        sch_man_district_id: '0',
        sch_man_upazilla_id: '0',
        sch_man_union_id: '0',
        sch_man_village: '',
        sch_man_village_bn: '',
        sch_man_mobile_no: '',
        pump_division_id: '0',
        pump_district_id: '0',
        pump_upazilla_id: '0',
        pump_union_id: '0',
        pump_latitude: '',
        pump_longitude: '',
        pump_mauza_no: '',
        pump_mauza_no_bn: '',
        pump_jl_no: '',
        pump_jl_no_bn: '',
        pump_plot_no: '',
        pump_plot_no_bn: '',
        total_farmer: '',
        command_area_hector: '',
        sch_man_photo: [],
        general_minutes: [],
        scheme_lands: [],
        scheme_map: [],
        affidavit_id: '0',
        status: 0,
        payment_status: 0
      },
      document: {
        user_id: this.$store.state.Auth.authUser.id,
        document_title: '',
        document_title_bn: '',
        attachment: []
      },
      documents: [],
      isDocument: false,
      upload_attachment: '',
      totalDocument: 0,
      districtList: [],
      upazilaList: [],
      unionList: [],
      schManDistrictList: [],
      schManUpazilaList: [],
      schManUnionList: [],
      pumpDistrictList: [],
      pumpUpazilaList: [],
      pumpUnionList: [],
      affidavitData: '',
      farmer_id: 0
    }
  },
  async created () {
    const id = this.$route.params.id === undefined ? 0 : parseInt(this.$route.params.id)
    if (id !== 0) {
      this.farmer_id = id
      this.schemeApplication = this.getSchemeApplicationData()
      Object.assign(this.addressHolder, this.schemeApplication)
      Object.assign(this.addressHolderPump, this.schemeApplication)
      if (this.schemeApplication.payment_status !== 0 && this.schemeApplication.payment_status !== 4 && this.schemeApplication.payment_status !== 5) {
        await this.$router.push('/irrigation/scheme-application')
      }
      this.getDocuments(id)
    } else {
      this.farmer_id = 0
    }
    this.checkUser()
    this.schemeApplication.far_mobile_no = this.$store.state.Auth.authUser.mobile_no
    this.load = false

    // organization selected when url from portal
    if (this.$route.query.org_id && parseInt(this.$route.query.org_id) === 15) {
      this.schemeApplication.org_id = 15
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    saveBtnName () {
      return this.farmer_id !== 0 ? this.$t('externalUserIrrigation.update_application') : this.$t('externalUserIrrigation.save_application')
    },
    affidavitList: function () {
      return this.$store.state.ExternalUserIrrigation.affidavitList
    },
    divisionList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.divisionList
    },
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
    }
  },
  watch: {
    'schemeApplication.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.schemeTypeList = this.getSchemeTypeList(newVal)
      }
    },
    'addressHolder.far_division_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.districtList = this.getDistrictList(newVal)
        }
    },
    'addressHolder.far_district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
      }
    },
    'addressHolder.far_upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.unionList = this.getUnionList(newVal)
        this.officeList = this.getOfficeList(newVal)
      }
    },
    'schemeApplication.sch_man_division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.schManDistrictList = this.getDistrictList(newVal)
      }
    },
    'schemeApplication.sch_man_district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.schManUpazilaList = this.getUpazilaList(newVal)
      }
    },
    'schemeApplication.sch_man_upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.schManUnionList = this.getUnionList(newVal)
      }
    },
    'addressHolderPump.pump_division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpDistrictList = this.getDistrictList(newVal)
      }
    },
    'addressHolderPump.pump_district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpUpazilaList = this.getUpazilaList(newVal)
      }
    },
    'addressHolderPump.pump_upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpUnionList = this.getUnionList(newVal)
      }
    },
    'schemeApplication.scheme_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAffidavitList(newVal)
        this.subSchemeTypeList = this.getSubSchemeTypeList(newVal)
        this.pumpCapacityList = this.getPumpCapacityList(newVal)
      }
    },
    sameAsFarmerProfile (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          this.addressHolder = Object.freeze(this.schemeApplication)
        } else {
          this.addressHolder = {
            far_division_id: '0',
            far_district_id: '0',
            far_upazilla_id: '0',
            far_union_id: '0'
          }
        }
      }
    },
    sameAsFarmerProfilePumpAddress (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (newVal) {
                this.addressHolderPump = Object.freeze(
                {
                    pump_division_id: this.schemeApplication.far_division_id,
                    pump_district_id: this.schemeApplication.far_district_id,
                    pump_upazilla_id: this.schemeApplication.far_upazilla_id,
                    pump_union_id: this.schemeApplication.far_union_id
                })
            } else {
                this.addressHolderPump = {
                    pump_division_id: '0',
                    pump_district_id: '0',
                    pump_upazilla_id: '0',
                    pump_union_id: '0'
                }
            }
        }
    }
  },
  methods: {
    setschemeAddress () {
      this.schemeApplication = Object.assign({}, this.schemeApplication,
      {
        sch_man_name: this.schemeApplication.name,
        sch_man_name_bn: this.schemeApplication.name_bn,
        sch_man_father_name: this.schemeApplication.father_name,
        sch_man_father_name_bn: this.schemeApplication.father_name_bn,
        sch_man_mother_name: this.schemeApplication.mother_name,
        sch_man_mother_name_bn: this.schemeApplication.mother_name_bn,
        sch_man_nid: this.schemeApplication.nid,
        sch_man_division_id: this.schemeApplication.far_division_id,
        sch_man_district_id: this.schemeApplication.far_district_id,
        sch_man_upazilla_id: this.schemeApplication.far_upazilla_id,
        sch_man_union_id: this.schemeApplication.far_union_id,
        sch_man_village: this.schemeApplication.far_village,
        sch_man_village_bn: this.schemeApplication.far_village_bn,
        sch_man_mobile_no: this.schemeApplication.far_mobile_no
      })
    },
    setPumpAddress () {
      this.schemeApplication = Object.assign({}, this.schemeApplication,
      {
        pump_division_id: this.schemeApplication.sch_man_division_id,
        pump_district_id: this.schemeApplication.sch_man_district_id,
        pump_upazilla_id: this.schemeApplication.sch_man_upazilla_id,
        pump_union_id: this.schemeApplication.sch_man_union_id
      })
    },
    getSchemeTypeList (orgId) {
      return this.$store.state.ExternalUserIrrigation.schemeTypeList.filter(item => item.org_id === orgId && item.status === 0)
    },
    getSchemeApplicationData () {
      var apps = Object.assign({}, this.$store.state.ExternalUserIrrigation.schemeApplicationLists.find(item => item.id === this.farmer_id))
      const fileList = ['general_minutes', 'scheme_lands', 'scheme_map']
      /* eslint-disable no-unused-vars */
      Object.keys(apps).map((key, index) => {
        if (fileList.includes(key)) {
          apps[key] = []
        }
      })
      /* eslint-enable no-unused-vars */
      return apps
    },
    onSchManFileChange (e) {
      this.schemeApplication.sch_man_photo = e.target.files[0]
    },
    onFileChangeGeneralMinutes (e) {
      this.schemeApplication.general_minutes = e.target.files[0]
    },
    onFileChangeSchemeLands (e) {
      this.schemeApplication.scheme_lands = e.target.files[0]
    },
    onFileChangeSchemeMap (e) {
      this.schemeApplication.scheme_map = e.target.files[0]
    },
    async register () {
      if (this.profileStatus === 0) {
        this.displayProfileCheckAlert()
        return
      }
      this.load = true
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })
      const loadinState = { loading: false, listReload: true }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.schemeApplication.far_division_id = this.addressHolder.far_division_id
      this.schemeApplication.far_district_id = this.addressHolder.far_district_id
      this.schemeApplication.far_upazilla_id = this.addressHolder.far_upazilla_id
      this.schemeApplication.far_union_id = this.addressHolder.far_union_id

      this.schemeApplication.pump_division_id = this.addressHolderPump.pump_division_id
      this.schemeApplication.pump_district_id = this.addressHolderPump.pump_district_id
      this.schemeApplication.pump_upazilla_id = this.addressHolderPump.pump_upazilla_id
      this.schemeApplication.pump_union_id = this.addressHolderPump.pump_union_id

      var formData = new FormData()

      Object.keys(this.schemeApplication).map(key => {
          formData.append(key, this.schemeApplication[key])
      })
      if (this.farmer_id !== 0) {
        if (this.schemeApplication.status === 4 || this.schemeApplication.status === 5) {
          this.updateStatus(this.schemeApplication.id)
          const result = await RestApi.postData(irriSchemeServiceBaseUrl, farmerSchemeApplicationStore, formData, config)
          if (result.success) {
            this.$toast.success({
              title: 'Success',
              message: result.message
            })
            this.$router.push('/irrigation/scheme-application')
          } else {
            this.$refs.form.setErrors(result.errors)
            this.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          this.load = false
        } else {
            formData.append('_method', 'PUT')
            const result = await RestApi.postData(irriSchemeServiceBaseUrl, `${farmerSchemeApplicationUpdate}/${this.farmer_id}`, formData)
            if (result.success) {
              this.$toast.success({
                title: 'Success',
                message: 'Data updated successfully',
                color: '#D6E09B'
              })
              setTimeout(async () => {
                  this.$router.push('/irrigation/scheme-application')
              }, 500)
            } else {
              this.$refs.form.setErrors(result.errors)
              this.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
          this.load = false
        }
      } else {
        const result = await RestApi.postData(irriSchemeServiceBaseUrl, farmerSchemeApplicationStore, formData, config)
        if (result.success) {
          this.$toast.success({
            title: 'Success',
            message: result.message
          })
          setTimeout(async () => {
            this.$router.push('/irrigation/scheme-application')
          }, 500)
        } else {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      }
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', loadinState)
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList

      if (divisionId) {
        return districtList.filter(item => item.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(item => item.district_id === districtId && item.status === 0)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(item => item.upazilla_id === upazilaId && item.status === 0)
      }
      return unionList
    },
    getOfficeList (upazilaId = null) {
      const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(office => office.district_id === this.addressHolder.far_district_id && office.status === 0)
      if (upazilaId) {
        const tmpOfficeList = officeList.filter(item => item.upazilla_id === upazilaId && item.status === 0)
        if (tmpOfficeList.length > 0) {
          return tmpOfficeList
        }
      }
      return officeList
    },
    getAffidavitList () {
      const affidavitList = this.$store.state.ExternalUserIrrigation.affidavitList
      const data = affidavitList.find(affidavit => affidavit.scheme_type_id === this.schemeApplication.scheme_type_id)
      if (data) {
        if (this.$i18n.locale === 'bn') {
          this.affidavitData = data.affidavit_bn
        } else {
          this.affidavitData = data.affidavit
        }
      } else {
        this.affidavitData = ''
      }
    },
    getSubSchemeTypeList (type) {
      const orgId = this.schemeApplication.org_id
      return this.$store.state.ExternalUserIrrigation.subSchemeTypeList.filter(item => item.master_scheme_type_id === type && item.org_id === orgId && item.status === 0)
    },
    getPumpCapacityList (type) {
      const orgId = this.schemeApplication.org_id
      const pumpCapacityList = this.$store.state.ExternalUserIrrigation.pumpCapacityList.filter(item => item.master_scheme_type_id === type && item.org_id === orgId && item.status === 0)
      return pumpCapacityList.map(item => {
        return Object.assign(item, { text: item.capacity, value: item.id })
      })
    },
    async updateStatus (AppId) {
      await RestApi.postData(irriSchemeServiceBaseUrl, `${updateStatus}/${AppId}`).then(response => {})
    },
    checkUser () {
      this.preConditionLoader = true
      RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo + '?with_data=' + false).then(response => {
        if (response.success) {
          this.profileStatus = response.data.status === 2 ? 4 : 0
          this.schemeApplication = Object.assign(this.schemeApplication, response.data)
        }
        this.load = false
        this.preConditionLoader = false
      })
    },
    getDocuments (applicationId) {
      RestApi.getData(irriSchemeServiceBaseUrl, getDocument + '/' + applicationId).then(response => {
        if (response.success) {
          this.isDocument = true
          this.documents = response.data.length > 0 ? response.data : []
        }
      })
    },
    onMultipleFileChange (e) {
      this.upload_attachment = e.target.files[0]
    },
    async saveDocument () {
      var formData = new FormData()
      Object.keys(this.document).map(key => {
        if (key === 'attachment') {
          formData.append(key, this.upload_attachment)
        } else {
          formData.append(key, this.document[key])
        }
      })
      const result = await RestApi.postData(irriSchemeServiceBaseUrl, documentStore, formData, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      if (result.success) {
        this.document.document_title = this.document.document_title_bn = ''
        this.document.attachment = []
        this.totalDocument += 1
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    deleteAttachment (documentId, index) {
      RestApi.deleteData(irriSchemeServiceBaseUrl, destroyDocument + '/' + documentId).then(response => {
          if (response.success) {
              this.documents.splice(index, 1)
              this.$toast.success({
                  title: 'Success',
                  message: 'Document remove successfully',
                  color: '#D6E09B'
              })
          }
      })
    },
    displayProfileCheckAlert () {
      if (this.profileStatus !== 0) {
        return
      }
      // const msg = this.$t('externalPanel.profile_complete_msg_part1') + '<a href="/irrigation/my-profile">links</a> ' + this.$t('externalPanel.profile_complete_msg_part2')
      this.$swal({
        title: this.$t('externalPanel.profile_complete_msg'),
        showCloseButton: true,
        confirmButtonText: 'Close',
        // showCancelButton: true,
        // html: msg
        focusConfirm: false
      })
    }
  }
}
</script>
